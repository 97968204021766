<template>
  <v-container
    v-if="company"
    class="layout-container"
  >
    <BaseHeader
      :title="$t('license-agreement.title')"
      :style-variant="10"
    >
      <template v-slot:design>
        <HeaderDesign />
      </template>

      <template v-slot:desc>
        <p>
          {{ $t('license-agreement.body-texts.0') }}.<br>
          {{ $t('license-agreement.body-texts.1') }}<br>
          <ul>
            <li>{{ $t('license-agreement.body-texts.2') }}</li>
            <li v-if="licenseAgreementHasAvv">
              {{ $t('license-agreement.body-texts.3') }}
            </li>
          </ul>
        </p>
      </template>
      <template v-slot:content>
        <v-breadcrumbs :items="breadcrumbItems" />
      </template>
    </BaseHeader>

    <v-card
      v-if="isContractOwner"
      outlined
      style="overflow:hidden"
    >
      <v-row>
        <v-col
          v-if="$vuetify.breakpoint.smAndUp"
          sm="3"
        >
          <div class="design-wrapper">
            <Leaf
              class="design-elements"
              width="1000"
              style="top:-1200px; right: 100px; opacity: 0.2"
              fill
            />

            <Leaf
              class="design-elements"
              width="60"
              style="top:10px; right: 150px; opacity: 0.8"
              stroke
              :stroke-width="1"
            />

            <Leaf
              class="design-elements"
              width="60"
              style="top:10px; right:130px; opacity: 0.3"
              fill
            />

            <SingleCircle
              class="design-elements"
              width="30px"
              style="top: 130px; right: 210px; opacity: 0.8"
              stroke
            />

            <SingleCircle
              class="design-elements"
              width="30px"
              style="top: 130px; right: 200px; opacity: 0.3"
              fill
            />
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="9"
          class="pa-6"
        >
          <v-alert
            v-if="showUpdateWarning"
            type="warning"
          >
            {{ $t('alerts.license-agreement.warning') }}
          </v-alert>

          <div class="d-flex align-center">
            <v-icon class="mr-2">
              mdi-file
            </v-icon>
            <a
              :href="licenseUrl"
              target="_blank"
              rel="noopener noreferrer"
              class="text-decoration-none"
            >
              <h4 class="pb-0 mb-0 ">
                {{ $t('license-agreement.title-contract') }}
                <v-icon
                  color="primary"
                  size="14"
                >
                  mdi-open-in-new
                </v-icon>
              </h4>
            </a>
          </div>

          <div
            v-if="showDefaultText"
            class="pt-2"
          >
            <span>
              {{ $t('alerts.license-agreement.info') }}
            </span>
          </div>

          <div class="pt-2">
            <v-checkbox
              v-model="isAccept"
              :disabled="!canAccept"
              :label="$t('license-agreement.accept-terms')"
            />
            <p
              v-if="company.licenseAgreement?.acceptedAt"
              class="d-flex align-center"
            >
              <v-icon class="mr-2">
                mdi-calendar
              </v-icon>
              {{ $t('license-agreement.last-accepted-at') }}:  {{ formatDate(company.licenseAgreement.acceptedAt) }}
            </p>
          </div>

          <div
            v-if="canAccept"
            class="justify-end d-flex"
          >
            <v-btn
              class="primary"
              :disabled="!isAccept"
              @click="submit"
            >
              {{ $t('buttons.avv.cta') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      v-else
      outlined
      class="pa-8"
    >
      <div v-if="!isAccept">
        <p
          v-html="$t('license-agreement.not-accepted-text', { readablePlatformName })"
        />
        <p
          v-html="$t('license-agreement.reminder-text')"
        />
      </div>
      <div v-else>
        <p
          v-html="$t('license-agreement.accepted-text')"
        />
      </div>
    </v-card>
  </v-container>
</template>

<script>
import LICENSE_AGREEMENT from '@/queries/LicenseAgreement.gql'
import UPDATE_LICENSE_AGREEMENT from '@/queries/UpdateLicenseAgreement.gql'
import featureMixin from '@/mixins/feature'
import HeaderDesign from '@/modules/licenseAgreement/HeaderDesign.vue'
import BaseHeader from '@/components/BaseHeader.vue'
import Leaf from '@/components/design-elements/leaf.vue'
import SingleCircle from '@/components/design-elements/singleCircle.vue'
import brandingMixin from '@/mixins/branding'
import bus, { eventNames } from '@/lib/eventBus'

export default {
  components: { BaseHeader, Leaf, SingleCircle, HeaderDesign },
  mixins: [featureMixin, brandingMixin],
  data () {
    return {
      isAccept: false,
      canAccept: true
    }
  },
  computed: {
    isContractOwner () {
      return this.$auth.user.isContractOwner
    },
    licenseUrl () {
      return this.getFeature(this.featureNames.LICENSE_AGREEMENT).config.licenseUrl
    },
    showDefaultText () {
      return !this.company.licenseAgreement?.acceptedAt
    },
    showUpdateWarning () {
      return this.company.licenseAgreement.acceptedAt && this.updatedLicenseAgreementExists
    },
    updatedLicenseAgreementExists () {
      return new Date(this.company.licenseAgreement.acceptedAt).getTime() < new Date(this.getFeature(this.featureNames.LICENSE_AGREEMENT).config.lastModifiedAt).getTime()
    },
    licenseAgreementHasAvv () {
      return this.getFeature(this.featureNames.LICENSE_AGREEMENT).config.hasAvv
    },
    breadcrumbItems () {
      return [
        { text: this.$t('navigation.labels.imprint'), to: { path: '/legal' }, disabled: false, exact: true },
        { text: this.$t('license-agreement.title-short') }
      ]
    }
  },
  methods: {
    async submit () {
      try {
        const { data: { updateLicenseAgreement } } = await this.$apollo.mutate({
          mutation: UPDATE_LICENSE_AGREEMENT,
          variables: {
            companyId: this.$auth.user.companyId
          },
          update: (store, { data: { updateLicenseAgreement } }) => {
            const data = store.readQuery({ query: LICENSE_AGREEMENT, variables: { id: this.$auth.user.companyId } })
            data.company.licenseAgreement.acceptedAt = updateLicenseAgreement
            store.writeQuery({ query: LICENSE_AGREEMENT, data, variables: { id: this.$auth.user.companyId } })
          }
        })

        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.license-agreement.success') })

        this.updateAcceptUI(updateLicenseAgreement)
        this.$router.push('/dashboard')
      } catch (err) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.license-agreement.error') })
      }
    },

    updateAcceptUI (licenseAgreementAcceptedAt) {
      this.isAccept = !!(licenseAgreementAcceptedAt && !this.updatedLicenseAgreementExists)
      this.canAccept = !this.isAccept
    }
  },
  apollo: {
    company: {
      query: LICENSE_AGREEMENT,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      },
      result ({ data }) {
        if (!data) return
        this.updateAcceptUI(data.company.licenseAgreement.acceptedAt)
      },
      skip () {
        return this.$auth.loading || !this.$auth.isAuthenticated
      }
    }
  }
}
</script>
<style scoped>
.design-wrapper {
  position: relative;
  width:300px;
  height: 1px;
}

.design-elements{
  position: absolute;
  z-index: 1;
  pointer-events: none;
}
</style>
